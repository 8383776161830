<template>
<div id="">
  <Navbar></Navbar>
  <v-container grid-list-md>
    <Loading v-if="initialLoad"></Loading>

    <v-layout  wrap v-else>
      <v-layout  wrap>
      <v-flex xs12>
        <h1 class="display-2" v-if="visit">Complete your personal info to request a visit</h1>
        <h1 class="display-2" v-else>{{$t('bookingRequest.completeRequest')}}</h1>
      </v-flex>
    </v-layout>
    <v-layout  wrap>
      <v-flex xs12 sm5 order-sm2>
        <SummaryCard :room="room" :utilities="utilities.amount" :apartment="apartment" :rent="rent" :diffMid="diffMid" :diffMod="diffMod" :dates="contractDates" :serviceFee="commission" :discount="absDiscount" :total="total" :totalFx="totalEur" :currency="user.currency" :couple="couple" :visit='visit'></SummaryCard>
      </v-flex>
      <v-flex xs12 sm7>

        <v-stepper v-model="booking" vertical>
          <v-stepper-step id="HouseRulesStep" :complete="booking > 2" editable step="2">
            {{$t('bookingRequest.importantInfo')}}
            <small>{{$t('bookingRequest.checkRules')}}</small>
          </v-stepper-step>

          <v-stepper-content id="ContinueTo3" step="2">
            <v-carousel class="rules-carousel" light :cycle="false">
              <v-carousel-item color="primary" v-if="visit">

                  <v-layout justify-center ow wrap>
                    <v-flex xs10>
                      <v-layout  wrap>

                        <v-flex xs12 class="title">
                          Visit process
                        </v-flex>
                        <v-flex xs12 class="subheading">
                          {{$t('bookingRequest.nextSteps')}}:
                        </v-flex>
                        <v-layout align-center justify-center  wrap>
                          <v-flex xs3 class="hidden-xs-only text-lg-center">
                            <v-icon large color="primary">timelapse</v-icon>
                          </v-flex>
                          <v-flex xs11 sm9 class="pa-3">
                            <div class="pa-2">
                              Please fill out your personal information below to introduce you to the landlord to let him decide whether or not he would accept you in his room
                            </div>
                            <div class="pa-2">
                              Someone of the My Room Abroad team will get in touch with you to confirm the time and date of the visit.
                            </div>
                            <div class="pa-2">
                              After the visit you can book the room directly on My Room Abroad.
                            </div>

                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>
              </v-carousel-item>
              <v-carousel-item color="primary">
                  <v-layout justify-center ow wrap>
                    <v-flex xs10>
                      <v-layout  wrap>

                        <v-flex xs12 class="title">
                          {{$t('bookingRequest.process')}}
                        </v-flex>
                        <v-flex xs12 class="subheading">
                          {{$t('bookingRequest.nextSteps')}}:
                        </v-flex>
                        <v-layout align-center justify-center  wrap>
                          <v-flex xs3 class="hidden-xs-only text-lg-center">
                            <v-icon large color="primary">timelapse</v-icon>
                          </v-flex>
                          <v-flex xs11 sm9 class="pa-3">
                            <div class="pa-2">
                              {{$t('bookingRequest.timeline1')}} <span class="em">{{$t('bookingRequest.timelineBold')}}</span> {{$t('bookingRequest.timeline2')}}
                            </div>
                            <div class="pa-2">{{$t('userRequest.charge')}}</div>
                            <div class="em pa-2">{{$t('bookingRequest.afterPayment')}}</div>

                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>
              </v-carousel-item>
              <v-carousel-item color="primary">
                  <v-layout  wrap justify-center>
                    <v-flex xs10>
                      <v-layout  wrap>
                        <v-flex xs12 class="title">
                          {{$t('bookingRequest.whenArriving')}}
                        </v-flex>
                        <v-flex xs12 class="subheading">
                          {{$t('bookingRequest.guarantee')}}
                        </v-flex>
                        <v-layout justify-center align-center>
                          <v-flex xs3 class="hidden-xs-only text-lg-center">
                            <v-icon large color="primary">flight_land</v-icon>
                          </v-flex>
                          <v-flex xs11 sm9 class="pa-3">
                            <div class="pa-2">
                              {{$t('bookingRequest.refundText1')}} <span class="em">{{$t('bookingRequest.refundBold')}}</span> {{$t('bookingRequest.refundText2')}}
                            </div>
                            <div class="pa-2">{{$t('bookingRequest.arrival1')}}: </div>
                            <div class="font-weight-bold pa-2">{{$t('bookingRequest.arrival2')}} <span class="md-float-right">{{deposit}} NT$</span></div>
                            <div class="font-weight-bold pa-2">{{$t('bookingRequest.arrival3')}}</div>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>

              </v-carousel-item>
              <v-carousel-item color="primary">

                  <v-layout  wrap>
                    <v-flex xs1>

                    </v-flex>
                    <v-flex xs10>
                      <v-layout  wrap>

                        <v-flex xs12 class="title">
                          {{$t('bookingRequest.departureTitle')}}
                        </v-flex>
                        <v-flex xs12 class="subheading">
                          {{modFormattedDay}}
                        </v-flex>
                        <v-layout align-center justify-center  wrap>
                          <v-flex xs3 class="hidden-xs-only text-lg-center">
                            <v-icon large color="primary">flight_takeoff</v-icon>
                          </v-flex>
                          <v-flex xs11 sm9 class="pa-3">
                            <div class="pa-2">
                              {{$t('bookingRequest.departure1')}}
                            </div>
                            <div class="pa-2">
                              {{$t('bookingRequest.departure2')}}
                            </div>

                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>
              </v-carousel-item>
              <v-carousel-item color="primary">

                  <v-layout  wrap>
                    <v-flex xs1>

                    </v-flex>
                    <v-flex xs10>
                      <v-layout  wrap>

                        <v-flex xs12 class="title">
                          {{$t('contractDetails.rules')}}
                        </v-flex>
                        <v-flex xs12 class="subheading">
                          {{$t('bookingRequest.rules1')}}:
                        </v-flex>
                        <v-layout align-center justify-center  wrap>
                          <v-flex xs3 class="hidden-xs-only pa-3 text-lg-center">
                            <v-icon large color="primary">description</v-icon>
                          </v-flex>
                          <v-flex xs10 sm9 class="pa-1">
                            <div class="pa-1" v-for="(rule,index) in apartment.contractDetails.rules" :key="index">
                              <span class="houserules-item" v-if="rule.value">{{$t('contractDetails.rulesTexts.'+index)}}</span>
                            </div>

                          </v-flex>
                          <v-flex xs10 sm9>
                            <v-switch color='primary' id="agreeInsideCardLarge" v-model="agreeToHouserules" @change="changeAgree" class="hidden-sm-and-down" :label="$t('bookingRequest.agree1')"></v-switch>
                            <v-switch color='primary' id="agreeInsideCardSmall"v-model="agreeToHouserules" @change="changeAgree" class="hidden-md-and-up" :label="$t('bookingRequest.agree2')"></v-switch>

                          </v-flex>

                        </v-layout>
                      </v-layout>
                    </v-flex>
                  </v-layout>
              </v-carousel-item>
            </v-carousel>
            <div class="caption" v-if="!agreeToHouserules">
              {{$t('bookingRequest.agreeToRulesAbove')}}
              <v-switch color='primary' id="agreeOutsideCards" v-model="agreeToHouserules" @change="changeAgree" class="hidden-sm-and-down" :label="$t('bookingRequest.agree1')"></v-switch>

            </div>
          <v-btn color="primary" id="ContinueTo3" :disabled="!agreeToHouserules" @click="toStep(3)"><div class="" id="continueTo3">
            {{$t('bookingRequest.continue')}}
          </div></v-btn>
          </v-stepper-content>

          <v-stepper-step id="Step3":complete="booking > 3" editable step="3">{{$t('bookingRequest.personalInfo')}}</v-stepper-step>

          <v-stepper-content step="3">
            <v-text-field v-model.trim="user.name" :label="$t('profile.name')" :error-messages="nameError" :hint="$t('profile.nameHint')" @change="$v.user.name.$touch()"></v-text-field>
            <v-text-field v-model="user.email" readonly :label="$t('tenants.email')"></v-text-field>
            <v-text-field v-model.trim="user.phone" hint="Format: +32XXXXXXXXX" :error-messages="phoneError" :label="'Phone'" @change="$v.user.phone.$touch()" ></v-text-field>
            <v-menu ref="birthdayMenu" :close-on-content-click="false" v-model="birthdayMenu" offset-y full-width :nudge-right="40" min-width="290px">
              <v-text-field slot="activator" :label="$t('profile.birthday')" v-model.trim="user.birthday" prepend-icon="event" readonly></v-text-field>
              <v-date-picker :locale="$vuetify.lang.current" ref="picker" v-model="user.birthday" :max="maxBday" @change="$refs.birthdayMenu.save()" min="1950-01-01"></v-date-picker>
            </v-menu>
            <v-select :items="communicationOptions" v-model="user.communication" :label="$t('profile.preferredMethodHint')"></v-select>
            <v-text-field v-model.trim="user.appId" :label="$t('pricing.phoneOrId')"></v-text-field>
            <v-select :items="genders" v-model="user.gender" :label="$t('profile.gender')"></v-select>
            <NationalityDropdown :nationality="user.nationality" @changeNationality="user.nationality=$event"></NationalityDropdown>
            <v-text-field v-model.trim="user.passport" :label="$t('profile.passport')" @change="$v.user.passport.$touch()"></v-text-field>
            <v-select :items="coupleVals" item-disabled="disabled" v-model="userIs" :label="$t('bookingRequest.coupleAsk')">
            </v-select>
            <v-select :items="occupationOptions"  v-model="user.occupation" :label="$t('profile.occupation')" :hint="$t('profile.occupationHint')"></v-select>
            <v-text-field v-model="user.school" :label="$t('profile.school')" :hint="$t('profile.schoolHint')"></v-text-field>
            <v-textarea v-model.trim="user.about" @change="$v.user.about.$touch()" :label="$t('profile.about')" :error-messages="aboutError" :hint="$t('profile.aboutHint')">
            </v-textarea>
            <v-textarea v-model.trim="user.timeslot" v-if="visit" label="Available timeslots" hint="Please give us at least 3 timeslots when you are available (e.g.: Monday Afternoon)">
            </v-textarea>
            <v-btn color="primary" id="ContinueTo4" @click="toStep(4)" :disabled="$v.user.$invalid || !agreeToHouserules">{{$t('bookingRequest.continue')}}</v-btn>


          </v-stepper-content>
          <v-stepper-step id="Step4Visit" :complete="booking > 4" step="4" v-if="visit">Confirm Visit</v-stepper-step>
          <v-stepper-step id="Step4Booking":complete="booking > 4" step="4" v-else>{{$t('userRequest.payment')}}</v-stepper-step>
          <v-stepper-content step="4" v-if="visit">
            <v-layout  wrap>
              <v-flex xs12>
                To avoid useless visits, we ask our users to make a <span class="font-weight-bold">Video</span> of each of their visits. This way you can revisit the room yourself to help make up your mind and you might help others in their booking decision and make them save a lot of time. (Video's are best when you keep your phone in landscape mode)
                <br>
                As a reward for sending us the video of your visit, we will offer an extra visit request.
              </v-flex>
              <v-flex xs12>

                <v-checkbox label="I will make a VIDEO of my visit" id="willMakeVideoCB" v-model="visit" value="value" class="videoClass">
                  <template v-slot:label >
                    <div class="" id="willDoThat">
                      I will make an awesome Video of my visit
                    </div>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-btn color="primary" id="ConfirmVisit1" @click="bookConfVisit=true">{{$t("requestsInbox.confirm")}}</v-btn>
            </v-layout>



            <v-dialog v-model="bookConfVisit" persistent max-width="290">
                <!-- <v-btn slot="activator" color="rgb(255,192,0)" dark :disabled="sending" v-if="visit">Request visit</v-btn>
                <v-btn slot="activator" color="primary" :disabled="sending" v-else>{{$t('bookingRequest.confirmBooking')}}</v-btn> -->

              <v-card>
                <v-card-title class="headline">{{$t('userRequest.contractDates')}}</v-card-title>
                <v-card-text v-if="diffMid || diffMod">{{$t("bookingRequest.diffDates1",{mid:formattedContractDates.mid,mod:formattedContractDates.mod})}}
                  <br>{{$t('bookingRequest.diffDates2')}}

              </v-card-text>
              <v-card-text v-else>
                {{$t('bookingRequest.sameDates',{mid:formattedContractDates.mid,mod:formattedContractDates.mod})}}
                <br>
                <div class="" >
                  Please confirm you are still interested in this listing.
                </div>
              </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" id="CancelDates" :disabled="sending" flat @click.native="bookConfVisit = false">{{$t("datePicker.cancel")}}</v-btn>
                  <v-btn color="rgb(255,192,0)" id="confirmVisit3" :disabled="sending" flat @click.native="checkUserVisits"><div class="" id="confirmVisit3">
                  {{$t("requestsInbox.confirm")}}</div></v-btn>
                  <!-- <v-btn color="primary" :disabled="sending" flat @click.native="checkUserBookings" v-else>{{$t("requestsInbox.confirm")}}</v-btn> -->
                </v-card-actions>
              </v-card>
            </v-dialog>





          </v-stepper-content>
          <v-stepper-content step="4" v-else>
            <v-text-field :error-messages="couponError" v-model="coupon" :label="$t('bookingRequest.coupon')"></v-text-field>
            <v-btn color="primary" id="ValidateCoupon" :disabled="disableCoupon" @click="checkCoupon">{{$t('bookingRequest.validateCoupon')}}</v-btn>
            <v-layout  wrap>
              <v-flex xs12 class="font-weight-bold">
                {{$t('bookingRequest.payment1')}}
              </v-flex>
              <!-- <v-flex xs12 v-else>
                {{$t('bookingRequest.payment2')}}
              </v-flex>
              <v-flex xs12>
                {{$t('bookingRequest.payment3')}}
              </v-flex>
            </v-layout>
            <v-select :items="currencyOptions" @change="getCurrency" v-model="user.currency" :label="$t('bookingRequest.payment4')" :hint="$t('bookingRequest.payment4Hint')"></v-select>
            <v-switch :label="$t('bookingRequest.payment5')" v-model="rents"></v-switch> -->

            <v-flex xs12>
              Please enter your Credit Card (CVC are the 3 numbers on the backside)
            </v-flex>

            </v-layout>

            <v-layout  wrap>
              <div class="pa-1">
                <AddCard user="user" @confirmBook="bookConf=true" :visit="visit" @cardOk="cardBad=false" @cardError="bookConf=false"></AddCard>
              </div>

            </v-layout>
            <v-layout pa-2  wrap>
              <v-flex xs6>
                <v-switch id="ReccuringRent" @change="changeAllRent" class='pl-2' label="Automatic Rent Payment" v-model="reccRent"></v-switch>
              </v-flex>
              <!-- <v-flex xs6>
                <v-switch id="AllRent" label="Pay all rent at once" @change="changeReccRent" v-model="allRent"></v-switch>
              </v-flex> -->
            </v-layout>
            <!-- <v-layout pa-2 class="boxed mx-2" v-if="allRent==true" row wrap>
              Once your booking is accepted your rent will be paid all at once. This way you will not have to worry about potentially failed payments.
              <br>
              <br>
              <span>The total we will charge you when your booking is accepted: <span class="font-weight-bold">{{totalRent}} TW$</span></span>

            </v-layout> -->
            <v-layout pa-2 class="boxed mx-2" v-if="reccRent==true"  wrap>
              <v-flex xs12>
                This payment will repeat every month until the end of your lease.
              </v-flex>
              <v-flex xs12>
                <v-layout  wrap>
                  <v-flex xs6>
                    <div class="font-weight-bold rent-title" >
                      Monthly due date
                    </div>
                    <div class="pa-2 my-1 rentInput">
                      {{dueDate}}
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div class="font-weight-bold rent-title" >
                      Monthly rent amount
                    </div>
                    <div class="pa-2 my-1 rentInput">
                      TW$  {{rent}} <small> {{utils}}</small>
                    </div>
                    <small class="pa-2">+ 2.8% credit card processing fees</small>
                  </v-flex>
                </v-layout>
                <v-layout  wrap>
                  <v-flex xs6>
                    <div class="font-weight-bold rent-title" >
                      Next rent payment
                    </div>
                    <div class="pa-2 my-1 rentInput">
                      <v-icon>calendar_today</v-icon> {{startDate | dates}}
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

<!-- ALSO EDIT THE BUTTON IN DIALOGQ -->
            <v-dialog v-model="bookConf" persistent max-width="290">
                <!-- <v-btn slot="activator" color="rgb(255,192,0)" dark :disabled="sending" v-if="visit">Request visit</v-btn>
                <v-btn slot="activator" color="primary" :disabled="sending" v-else>{{$t('bookingRequest.confirmBooking')}}</v-btn> -->

              <v-card>
                <v-card-title class="headline">{{$t('userRequest.contractDates')}}</v-card-title>
                <v-card-text v-if="diffMid || diffMod">{{$t("bookingRequest.diffDates1",{mid:formattedContractDates.mid,mod:formattedContractDates.mod})}}
                  <br>{{$t('bookingRequest.diffDates2')}}

              </v-card-text>
              <v-card-text v-else>
                {{$t('bookingRequest.sameDates',{mid:formattedContractDates.mid,mod:formattedContractDates.mod})}}
                <br>
                <div class="" v-if="visit">
                  Please confirm you are still interested in this listing.
                </div>
                <div class="" v-else>
                  {{$t('bookingRequest.diffDates2')}}
                </div>
              </v-card-text>
              <v-card-text v-if="cardBad">

                <v-layout  wrap>
                  <v-flex xs12>
                    <span >We're validating your card. Please wait a moment...</span>
                  </v-flex>
                  <v-flex xs12 class="text-xs-center">

                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </v-flex>
                </v-layout>
              </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" id="CancelVisit" :disabled="sending" flat @click.native="bookConf = false">{{$t("datePicker.cancel")}}</v-btn>
                  <v-btn color="rgb(255,192,0)" id="confirmVisit3" :disabled="sending" flat @click.native="checkUserVisits" v-if="visit"><div class="" id="confirmVisit3">{{$t("requestsInbox.confirm")}}</div></v-btn>
                  <v-btn color="primary" id="confirmBooking2" :disabled="sending || cardBad" flat @click.native="checkUserBookings" v-else> <div class="" id="confirmBooking2">
                    {{$t("requestsInbox.confirm")}}
                  </div> </v-btn>
                  <!-- <v-btn color="primary" :disabled="sending" flat @click.native="checkUserBookings" v-else>{{$t("requestsInbox.confirm")}}</v-btn> -->
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-stepper-content>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-layout>
  </v-container>
  <v-dialog v-model="tooManyVisitsDialog" max-width="500">
    <v-card>
      <v-card-title class="headline">Maximum number of visits requested</v-card-title>

      <v-card-text>
        You have already requested 3 visits on My Room Abroad, which is our limit.
        As too avoid wasting time on useless uninformed visits, we have limited the number of visits on our platform to 3.
        If you believe something wrong happened or have a good reason to make an exception for your case, please send us a message.
        You can however book your room directly afer doing a virtual tour on My Room Abroad.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" id="CancelTooManyVisits" @click="tooManyVisitsDialog=false">Cancel</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showMessage"
    color="success"
    :timeout="6000"
  >
    Coupon applied successfully!
    <v-btn flat @click.native="showMessage = false">Close</v-btn>
  </v-snackbar>

</div>
</template>
<script>
import axios from 'axios'
import {
  mapGetters,
  mapActions
} from 'vuex'
import * as types from '../../store/types'
import SummaryCard from './SummaryCard.vue'
import NationalityDropdown from '../shared/NationalityDropdown.vue'
import moment from 'moment'
import firebase from '../../FirebaseApp'
import AWSSES from '../../aws-ses'
import Loading from '../shared/Loading.vue'
import AddCard from '../shared/AddCard.vue'
import {
  required,
  helpers,
} from 'vuelidate/lib/validators'
const phone = (value) => {
  if (!helpers.req(value)){
    return true
  } else {
    let phoneRegex = /^\+?[1-9]\d{1,14}$/
    let valid = phoneRegex.test(String(value))
    return valid
  }

}

export default {
  name: "",
  data: () => ({
    birthdayMenu: false,
    bookConfVisit:false,
    booking: 2,
    rent:0,
    showMessage:false,
    disableCoupon:false,
    reccRent:true,
    allRent:false,
    deposit:0,
    visits:3,
    tooManyVisitsDialog:false,
    price:0,
    initialLoad:true,
    cardBad:true,
    currencyOptions: [{
        text: "New Taiwan Dollar (TWD)",
        value: "TWD"
      },
      {
        text: "Euro (EUR)",
        value: "EUR"
      },
      {
        text: "US Dollar (from any USD denominated bank accounts) (USD)",
        value: "USD"
      },
      {
        text: "GB Pound (from any bank account in the UK) (GBP)",
        value: 'GBP'
      },
      {
        text: "Australian Dollar (from any bank account in Australia) (AUD)",
        value: "AUD"
      },
      {
        text: "Chinese Yuan (CNY)",
        value: "CNY",
      },
      {
        text: "Honk Kong Dollar (HKD)",
        value: "HKD"
      },
      {
        text: "Japanese Yen (JPY)",
        value: "JPY",
      },
      {
        text: "Canadian Dollar (CAD)",
        value: "CAD",
      },
      {
        text: "South African Rand (ZAR)",
        value: "ZAR",
      },
      {
        text: "New Zealand Dollar (NZD)",
        value: "NZD"
      },
      {
        text: "Swiss Franc (CHF)",
        value: "CHF"
      },
      {
        text: "Swedish Kron (SEK)",
        value: "SEK"
      },
      {
        text: "Singapore Dollar (SGD)",
        value: "SGD",
      },
      {
        text: "Mexican Peso (MXN)",
        value: "MXN",
      },
      {
        text: "Thai Baht (THB)",
        value: "THB"
      }
    ],
    bookConf: false,
    sending: false,
    steppers: {
      general: {
        active: 'houserules',
        houserules: false,
        personalDetails: false,
        paymentDetails: false,
      },
      houserules: {
        active: "first",
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
      },
    },
    rents:true,
    agreeToHouserules: false,
    landlord: {},
    apartment: {
      contractDetails: {},
    },
    fx:1,
    coupon: "",
    discount: 0,
    couponError: "",
    dates: {
      mid: "",
      mod: "",
    },
    serviceFee: .2,
    // transactionCost: .05,
    midFormatted: "",
    modFormatted: "",
    couple: false,
    userIs: "",
    room: {
      general: {},
      billing: {},
      visual: {}
    },
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'Admin'
    },
    formattedContractDates:{
        mid:"",
        mod:"",
    },
    contractDates: {
      mid: "",
      mod: "",
    },
    listSaved:false,
    savedListingId:"",
  }),
  firebase() {
    return {
      coupons: {
        source:firebase.database().ref('rCoupons'),
        readyCallback:function(){
        }
      },
    }
  },

  methods: {

    ...mapActions({
      refreshUser: types.FETCH_USER
    }),
    changeAgree(val){
      dataLayer.push({
        'event':'changeAgreeToRules',
        'agreeVal':val,
      })
    },
    changeAllRent(){
      if (this.reccRent==true){
        this.allRent=false
      }
    },
    changeReccRent(){
      if (this.allRent==true){
        this.reccRent=false
      }
    },
    toStep(step){
      this.booking=step
      dataLayer.push({
        'event':'nextStep',
        'step':step,
      })
      window.scrollTo({
        top: 100,
        behavior: "smooth"
      })
    },
    formattedDate(dateString) {
      if (moment(dateString).isValid()) {
        return moment(dateString).format('YYYY-MM-DD')
      }
    },

    // setCouponValue(coupon){
    //   if (moment().isBefore(moment(coupon.expDate))||coupon.expDate==undefined){
    //
    //     switch (coupon.type) {
    //       case 'absolute':
    //         this.absDiscount=Number(coupon.value)
    //         break;
    //       case 'percentage':
    //         this.discount=Number(coupon.value)
    //         break
    //       default:
    //         this.couponError = "Something weird happened. Please contact My Room Abroad to validate your coupon"
    //
    //     }
    //   } else {
    //     this.couponError = "This coupon has expired."
    //   }
    // },
    checkCoupon(){
      // console.log('coupon');
      this.couponError = ""
      let vm=this
      let myCoupon = vm.coupons.find(function(el) {
        return el.name==vm.coupon
      })
      // console.log('coupon:',myCoupon)

      if (myCoupon==undefined ){
        this.couponError='Coupon invalid'
        dataLayer.push({
          'event':'tryCoupon',
          'couponName':this.coupon +' - '+ this.couponError
        })
      } else if (myCoupon.used==true){
        this.couponError='Coupon has been used'
        dataLayer.push({
          'event':'tryCoupon',
          'couponName':this.coupon +' - '+ this.couponError
        })
      } else if (moment(myCoupon.expDate).isBefore(moment())){
        this.couponError='Coupon has Expired'
        dataLayer.push({
          'event':'tryCoupon',
          'couponName':this.coupon +' - '+ this.couponError
        })
      } else if (myCoupon.user && myCoupon.user!=this.user.id) {
        this.couponError="You can't use this coupon."
        dataLayer.push({
          'event':'tryCoupon',
          'couponName':this.coupon +' - '+ this.couponError
        })
      } else {
        this.calcVal(myCoupon)
        this.showMessage=true
        this.disableCoupon=true
      }

    },
    calcVal(el){
      console.log(el)
      if (el.absVal==true){
        this.absDiscount=parseInt(el.val)
      } else {
        this.discount=parseInt(el.val)/100
      }
      dataLayer.push({
        'event':'couponValid',
        'couponName':this.coupon + this.discount.toString()
      })

    },
    // checkCoupon() {
    //   let allCoupons = globalVars.COUPONS
    //   this.couponError = ""
    //   // console.log(allCoupons)
    //   for (var element in allCoupons) {
    //     if (allCoupons.hasOwnProperty(element)) {
    //       if (allCoupons[element].code.toLowerCase() == this.coupon.toLowerCase()) {
    //         this.setCouponValue(allCoupons[element])
    //         return
    //       }
    //     }
    //   }
    //   this.couponError = "This coupon doesn't exist. Please check your spelling."
    // },

    checkUserVisits(){
      this.sending=true
      axios.get('users/'+this.user.id+'.json')
      .then(res=>{
        console.log(res.data);
        if (res.data.visits!=undefined){
          this.visits=Number(res.data.visits)
        }
        console.log(res.data.visits);
        if (res.data.visits!=undefined && Number(res.data.visits)<1){
          this.tooManyVisitsDialog=true
          this.bookConf=false
          this.visit
          this.sending=false
        } else {
          console.log('do the visit');
          this.confirmVisit()
        }
      })
      .catch (err=>{
        console.log(err);
        this.sending=false
      })
    },
    confirmVisit(){
        dataLayer.push({
          'event':'confirmVisit',
        })

        let d={
         tenantId: this.user.id,
         timeslot: this.user.timeslot,
         rents:this.rents,
         landlordId: this.apartment.general.landlordId,
         listingId: this.$route.params.id,
         location:this.apartment.general.location,
         discount: this.absDiscount,
         coupon:this.coupon,
         dates: this.contractDates,
         roomNumber: this.room.general.fullRoomNumber,
         realDates:this.dates,
         dateRequested: moment.tz("UTC"),
         type: this.$route.params.type,
         status: "Requested",
       }
      axios.post('visits.json?auth='+this.$store.state.auth.idToken,d)
      .then(()=>{
        this.visits--
        console.log(this.visits, 'the visits');
        axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{visits:this.visits})
        .then(()=>{
          this.sending=false
          let info={
            tenant:this.user.userName,
            landlord: this.landlord.userName,
            mid: moment(this.contractDates.mid).format("YYYY-MM-DD"),
            mod: moment(this.contractDates.mod).format("YYYY-MM-DD"),
            comment:this.user.timeslot,
            room:this.room.general.fullRoomNumber
          }

          AWSSES.emailingInfo(this.admin, info, 'AVisitRequestSent')
          this.$router.push('/')
        })
        .catch(err=>{
          console.log(err);
          this.sending=false
        })
      })
      .catch(err=>{
        console.log(err);
        this.sending=false
      })
    },
    checkUserBookings() {
      this.sending = true
      // console.log('users/'+this.user.id+'json');
      axios.get('users/'+this.user.id+'/bookingRequestOut.json')
      .then(res=>{
        // console.log(res.data);
        let bro=res.data
        // console.log(bro);
        if (bro==undefined||bro==""||!bro){
          this.confirmBooking()
        } else {
          axios.get('/bookingRequests/' + bro + '.json')
          .then(res => {
            // console.log(res)
            if (res.data.status == "Accepted" && moment().isBefore(res.data.dates.mod)) {
              alert("you already have a confirmed booking. Please go check it out.")
              this.$router.push('/userRequest')

            } else if (res.data.status == "Paid" && moment().isBefore(res.data.dates.mod)) {
              alert("you already have a confirmed booking. Please go check it out.")
              this.$router.push('/myBooking')
            } else if (res.data.status == "Declined" || res.data.status == "Past" || res.data.status == "Canceled") {
              this.confirmBooking()
              axios.patch('/users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
                bookingRequestOut: ""
              })
              .catch(err=>{
                console.log('error: ',err);
                this.sending=false
              })
            } else if (res.data.status == "Requested" && (moment(res.data.dateRequested).add(2,'d').isBefore(moment()))) {
              axios.patch('bookingRequests/' + bro + '.json' + '?auth=' + this.$store.state.auth.idToken, {
                status: 'Past'
              })
              .catch(err=>{
                console.log('error: ',err);
                this.sending=false
              })
              axios.patch('/users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
                bookingRequestOut: ""
              })
              .catch(err=>{
                console.log('error: ',err);
                this.sending=false
              })
              this.confirmBooking()
            } else if(moment(res.data.dates.mod).isBefore(moment())){
              this.this.confirmBooking()
            } else {
              // console.log('already have a booking');
              alert("You have already booked a room. First cancel your room if you want to change or wait for the landlord to respond. He will reply within 48hrs of your request.")
              this.$router.push('/userRequest')
              this.sending=false
            }
          })
          .catch(err=>{
            console.log('error: ',err);
            this.sending=false
          })
        }
      })
      .catch(err=>{
        console.log('error ',err);
      })
    },
    setCouponUsed(){
      if (this.coupon!=""){
        let coupon = this.coupons.find(el => el.name == this.coupon)
        console.log(coupon);
        if (coupon.sUse==true){
          console.log('single use, set used');
          axios.patch('rCoupons/' + coupon['.key'] + '.json?auth=' + this.$store.state.auth.idToken, {used:true})
          .then(res=>{
            console.log(res, ' set the coupon as used.');
          })
        }
      } else {
        console.log('No coupon');
      }
    },
    confirmBooking() {
      // console.log(res.data);
      // console.log(res.data[fromToCurrency])
      // console.log(this.user.currency);
      let bookingFx=1
      let cDates={
        mid: moment(this.contractDates.mid).format("YYYY-MM-DD"),
        mod: moment(this.contractDates.mod).format("YYYY-MM-DD")
      }
      let rDates={
        mid: moment(this.dates.mid).format("YYYY-MM-DD"),
        mod: moment(this.dates.mod).format("YYYY-MM-DD")
      }
      // console.log(bookingFx, 'booking fx')
      let id = this.$route.params.id
      let bookingRequest = {
        tenantId: this.user.id,
        rents:this.rents,
        landlordId: this.apartment.general.landlordId,
        listingId: id,
        location:this.apartment.general.location,
        price: this.roomPrice,
        oldNextAv: this.room.general.nextAvailability,
        discount: this.absDiscount,
        reccRent:this.reccRent,
        allRent:this.allRent,
        coupon: this.coupon,
        dates: cDates,
        realDates:rDates,
        utilities:this.utilities.amount,
        listUtil:this.utilities,
        dateRequested: moment(),
        currency: 'TWD',
        fx: bookingFx,
        status: 'Requested',
        type: this.$route.params.type
      }
      if ( this.room.billing.llPrice!=undefined && this.room.billing.llPrice!="") {
        bookingRequest.llPrice=this.room.billing.llPrice
      }
      try {
        this.setCouponUsed()
      } catch (e) {
        console.log(e);
      }
      // console.log('created the object')
      console.log(bookingRequest)
      axios.post('bookingRequests.json' + '?auth=' + this.$store.state.auth.idToken, bookingRequest)
        .then(res => {
          dataLayer.push({
            'event':'brEvent',
          })

          // sendEmail
          let bookingRequestId = res.data.name
          axios.patch('bookingRequests/' + bookingRequestId + '.json' + '?auth=' + this.$store.state.auth.idToken, {
            id: bookingRequestId
          })
          .catch(err=>{
            console.log('error: ',err);
          })

          axios.post('users/' + this.apartment.general.landlordId + '/bookingRequestsIn.json' + '?auth=' + this.$store.state.auth.idToken, {
            bookingRequestId: bookingRequestId,
            dateRequested:bookingRequest.dateRequested
          })
          .then(() => {
            // console.log('request added to the landlord')
          })
          .catch(err=>{
            console.log('error: ',err);
          })
          axios.get('users/' + this.apartment.general.landlordId + '/tbp.json')
          .then(res=>{
            // console.log(res.data,'TBP:')
            let tbp=0
            // console.log(isNaN(res.data),'isNaN?')
            if (!isNaN(res.data)){
              // console.log('its not NaN')
              tbp=res.data+1

            } else {
              // console.log('else of the nan')
              tbp=1
            }
            // console.log('tbp:',tbp)
            axios.patch('users/' + this.apartment.general.landlordId + '.json' + '?auth=' + this.$store.state.auth.idToken, {tbp:tbp})
          })
          .catch(err=>{
            console.log('error: ',err);
          })
          this.user.bookingRequestOut = bookingRequestId
          axios.patch('users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, this.user)
            .then(() => {
              // console.log("users", this.user, this.landlord, this.admin)
              let info={
                tenant:this.user.name,
                landlord: this.landlord.userName,
                mid: moment(this.contractDates.mid).format("YYYY-MM-DD"),
                mod: moment(this.contractDates.mod).format("YYYY-MM-DD"),
                amount:null,
                comment:null,
                room:this.room.general.fullRoomNumber
              }
              AWSSES.emailingInfo(this.user, info, 'TBookingRequestSent')
              AWSSES.emailingInfo(this.landlord,info, 'LLBookingRequestReceived')
              axios.get('lineFollow.json?orderBy="ll"&equalTo="'+this.landlord.id+'"')
              .then(res=>{
                console.log(res.data);
                let lineConvs=res.data
                if (lineConvs!=undefined && lineConvs!=null) {
                  let textMsg="Hi "+this.landlord.userName+"! \nYou have a new Booking Request on My Room Abroad for room "+this.room.general.fullRoomNumber+". You can see your booking requests here: https://myroomabroad.com/requestsInbox"
                  if (this.landlord.sendChinese==true){
                    textMsg="Hi "+ this.landlord.userName +"！\n恭喜您有一個新的訂房在My Room Abroad！\n房客想預定你的 "+this.room.general.fullRoomNumber +", 你可以在此連結看到你的訂房請求：https://myroomabroad.com/requestsInbox"
                  }
                  for (var i in lineConvs) {
                    if (lineConvs.hasOwnProperty(i)) {

                      let info={to: lineConvs[i].userId,messages:[{"type": "text","text": textMsg}]}
                      // console.log(info);
                      try{
                        axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/sendLineMessage', info, {
                          headers: {
                            'Content-Type': 'text/plain'
                          }
                        })
                        .then(res=>{
                          console.log(res);
                        })
                        .catch(err=>{
                          console.log(err);
                          throw (err)
                        })
                      }
                      catch(err){
                        console.log(err);
                      }
                    }
                  }
                }
              })
              .catch(err=>{
                console.log(err);
              })
              // AWSSES.emailing(this.admin, 'ABookingRequestSend')
              AWSSES.emailingInfo(this.admin, info, 'ABookingRequestSend')
              this.refreshUser()
              this.$router.push({
                name: 'userRequest'
              })
              // console.log('request added to the Tenant')
            })
            .catch(err=>{
              console.log('error: ',err);
            })

        })
        .catch(err=>{
          console.log('error: ',err);
        })
    },
    confirmRules() {
      this.bookConf = true

      if (this.agreeToHouserules) {
        this.steppers.general.houserules = true
        this.steppers.general.active = "personalDetails"
        if (screen.width <= 600) {
          window.scrollTo({
            top: 990,
            behavior: "smooth"
          })
        } else {
          window.scrollTo({
            top: 100,
            behavior: "smooth"
          })
        }
      } else {
        this.errorMessage.houserules = "please accept the houserules first"
      }
    },

    getCurrency(){
      // console.log(this.user.currency ,'user currencyOptions')
      if (this.user.currency==undefined){
        this.user.currency='EUR'
      }
      console.log(this.user.currency);
      axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=TWD_' + this.user.currency + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
      .then(res => {
        let fromToCurrency = "TWD_" + this.user.currency
        // console.log(res.data[fromToCurrency])
        if (this.user.currency!="TWD"&&this.user.currency!="NTD"){
          this.fx = res.data[fromToCurrency]*1.04
        } else {
          this.fx = res.data[fromToCurrency]
          this.user.currency="TWD"
        }
      })
      .catch(err=>{
        console.log('error: ',err);
        console.log(this.user.currency);
        this.fx=1
        this.user.currency="TWD"
      })
      axios.patch('users/'+ this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, this.user)
      .catch(err=>{
        console.log('error: ',err);
      })
    },
    calcMinMod(mid) {
      // console.log('MID: ', mid)
      // console.log()
      switch (this.apartment.contractDetails.contractLength) {
        case '1 month':
          // console.log('case1', moment(mid).add(1, 'M').subtract(1, 'd'))
          return moment(mid).add(1, 'M').subtract(1, 'd')
        case '3 months':
          // console.log('case2', moment(mid).add(3, 'M'))
          return moment(mid).add(3, 'M').subtract(1, 'd')
        case '5 months':
          // console.log('case3', moment(mid).add(5, 'M').subtract(1,'d'))
          return moment(mid).add(5, 'M').subtract(1, 'd')
        case '12 months (Sublease Allowed)':
          // console.log('case4', moment(mid).add(12, 'M'))
          return moment(mid).add(12, 'M').subtract(1, 'd')
        case '12 months':
          // console.log('case5', moment(mid).add(12, 'M'))
          return moment(mid).add(12, 'M').subtract(1, 'd')
        case 'Open contract':
          // console.log('case6')
          break
      }
    },
    setContractDates() {
      let minMod = moment()
      switch (this.apartment.contractDetails.contractFlexibility) {
        case 'Starts and ends any day':
          this.contractDates.mid = moment(this.dates.mid)
          minMod = moment(this.calcMinMod(this.contractDates.mid))
          // console.log('minMod is: ', minMod)
          if (moment(minMod).isAfter(moment(this.dates.mod))) {
            this.contractDates.mod = minMod
          } else {
            this.contractDates.mod = moment(this.dates.mod)
          }

          break
        case 'Starts any day for 1 month':
          this.contractDates.mid = moment(this.dates.mid)
          minMod = moment(this.calcMinMod(this.contractDates.mid))
          // console.log(minMod, 'MIN MOD ')
          if (moment(minMod).isAfter(moment(this.dates.mod))) {
            // console.log(minMod, moment(this.dates.mod), 'MIN is after mod ')
            if (minMod.date() > moment(this.dates.mid).date()) {
              this.contractDates.mod = minMod.add(1, 'M').date(moment(this.dates.mid).date())
            } else {
              this.contractDates.mod = minMod.date(moment(this.dates.mid).date())
            }
          } else {
            if (moment(this.dates.mod).date() > moment(this.dates.mid).date()) {
              this.contractDates.mod = moment(this.dates.mod).add(1, 'M').date(moment(this.dates.mid).date())
            } else {
              this.contractDates.mod = moment(this.dates.mod).date(moment(this.dates.mid).date())
            }
          }
          this.contractDates.mod = this.contractDates.mod.subtract(1, "d")
          break
        case 'Starts first or 15th':
          if (moment(this.dates.mid).date() >= 15) {
            this.contractDates.mid = moment(this.dates.mid).date(15)
          } else {
            this.contractDates.mid = moment(this.dates.mid).date(1)
          }
          minMod = this.calcMinMod(this.contractDates.mid)
          // console.log('minMod:', minMod)
          if (moment(minMod).isAfter(moment(this.dates.mod))) {
            if (minMod.date() >= 15) {
              this.contractDates.mod=moment(minMod).endOf('M')
              // console.log(minMod)
            } else {
              this.contractDates.mod = moment(minMod).date(14)
            }
          } else {
            if (moment(this.dates.mod).date() >= 15) {
              this.contractDates.mod = moment(this.dates.mod).endOf('M')
            } else {
              this.contractDates.mod = moment(this.dates.mod).date(14)
            }
          }
          break
        case 'Starts always on the first':
          this.contractDates.mid = moment(this.dates.mid).date(1)
          minMod = moment(this.calcMinMod(this.contractDates.mid))
          if (moment(minMod).isAfter(moment(this.dates.mod))) {
            this.contractDates.mod = moment(minMod).endOf('M')
          } else {
            this.contractDates.mod = moment(this.dates.mod).endOf('M')
          }
          break
      }
      this.formattedContractDates.mid = this.contractDates.mid.format("DD MMM YYYY")
      this.formattedContractDates.mod = this.contractDates.mod.format("DD MMM YYYY")
      this.checkPricing()
      if (moment(this.contractDates.mid).isBefore(moment(this.room.general.nextAvailability))) {
        this.contractDates.mid = this.room.general.nextAvailability
      }
      // console.log('contractDates')
      // this.formatContractDates()
      // console.log(this.contractDates)
    },
    checkPricing(){
      if (this.room.billing.flexPrice==true){
        let duration = moment.duration(this.contractDates.mod.diff(this.contractDates.mid))
        let months = Math.round(duration.asMonths()*2)/2
        console.log('Number of months',months);
        if (months<3 && this.room.billing.flexPricing.month!=undefined){
          this.rent=this.room.billing.flexPricing.month
          if (this.room.billing.flexPricing.monthD!=undefined){
            this.deposit=this.room.billing.flexPricing.monthD
          } else {
            this.deposit=this.room.billing.deposit
          }
        } else if (months<5) {
          this.rent=this.room.billing.flexPricing.three
          if (this.room.billing.flexPricing.threeD!=undefined){
            this.deposit=this.room.billing.flexPricing.threeD
          } else {
            this.deposit=this.room.billing.deposit
          }
        } else if (months<12) {
          this.rent=this.room.billing.flexPricing.five
          if (this.room.billing.flexPricing.fiveD!=undefined){
            this.deposit=this.room.billing.flexPricing.fiveD
          } else {
            this.deposit=this.room.billing.deposit
          }
        } else {
          this.rent=this.room.billing.flexPricing.year
          this.deposit=this.room.billing.flexPricing.yearD
          if (this.room.billing.flexPricing.yearD!=undefined){
            this.deposit=this.room.billing.flexPricing.yearD
          } else {
            this.deposit=this.room.billing.deposit
          }
        }
      } else {
        this.rent=this.room.billing.price
        this.deposit=this.room.billing.deposit
      }
      dataLayer.push({
        price:parseInt(this.rent),
        id: this.$route.params.id,
        margin:parseInt(this.rent)*.2,
      })
      console.log(this.rent, 'your rent will be');
    },

  },
  watch: {
    userIs: function() {
      if (this.userIs == "couple") {
        this.user.couple = 'couple'
        this.couple = true
      } else {
        this.user.couple = 'single'
        this.couple = false
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.user.id!=undefined&& this.listSaved && this.savedListingId!=""){
      axios.patch('users/'+this.user.id+'/book/'+this.savedListingId+'.json?auth='+this.$store.state.auth.idToken,{exitTS:moment()})
    }
    next()
  },
  created() {
    //do something after creating vue instance
    let vThis = this
    console.log(this.user.id, this.user.roleValue, 'check these');
    if (this.user.id!=undefined && this.user.roleValue<20){
      // console.log(do this.);
      this.listSaved=true
      let t={
        id: this.$route.params.id,
        type: this.$route.params.type,
        ts: moment(),
        user:this.user.id
      }
      axios.post('rBook.json?auth='+this.$store.state.auth.idToken,t)
      .then(res=>{
        this.savedListingId = res.data.name
      })
    }
    if (this.user.birthday != "") {
      this.user.birthday = moment(this.user.birthday).format('YYYY-MM-DD')
    }
    // console.log('Type: ', this.$route.params.type)
    this.dates.mid = moment(Number(this.$route.params.mid))
    this.midFormatted = moment(this.dates.mid).format('MMM YYYY')
    this.dates.mod = moment(Number(this.$route.params.mod))
    this.modFormatted = moment(this.dates.mod).format('MMM YYYY')
    this.modFormattedDay = moment(this.dates.mod).format('DD MMM YYYY')
    // console.log(this.dates, 'the dates')
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    if (this.user.couple == "couple") {
      this.couple = true
      this.userIs = "couple"
    } else {
      this.couple = false
      this.userIs = "single"

    }
    if (this.$route.params.type == "room") {
      this.id = this.$route.params.id
      axios.get('/rooms/' + vThis.$route.params.id + '.json')
        .then(res => {
          vThis.room = res.data
          if (vThis.room.billing.couplesAllowed==false){
            vThis.user.couple=="single"
            vThis.userIs="single"
            vThis.couple=false
          }
          axios.get('/apartments/' + vThis.room.apartmentId + '.json')
            .then(res => {
              vThis.apartment = res.data
              vThis.initialLoad=false
              vThis.setContractDates()
              this.getCurrency()
              axios.get('/users/' + vThis.apartment.general.landlordId + '.json')
                .then(res => {
                  vThis.landlord = res.data
                  vThis.landlord.id=vThis.apartment.general.landlordId
                })
                .catch(err=>{
                  console.log('error: ',err);
                })

            })
            .catch(err=>{
              console.log('error: ',err);
            })
        })
        .catch(err=>{
          console.log('error: ',err);
        })
    } else if (vThis.$route.params.type == "studio") {
      // console.log('go get the studio');
      axios.get('/studios/' + vThis.$route.params.id + '.json')
        .then(res => {
          vThis.initialLoad=false
          vThis.room = res.data
          if (vThis.room.billing.couplesAllowed==false){
            vThis.user.couple=="single"
            vThis.userIs="single"
            vThis.couple=false
          }
          vThis.apartment = res.data
          axios.get('/users/' + vThis.apartment.general.landlordId + '.json')
          .then(res => {
            vThis.landlord = res.data
            vThis.landlord.id=vThis.apartment.general.landlordId

          })
          .catch(err=>{
            console.log('error: ',err);
          })
          vThis.setContractDates()
          this.getCurrency()

        })
    } else if (vThis.$route.params.type =='apartment'){

      axios.get('/wholeApts/'+vThis.$route.params.id+'.json')
      .then(res =>{
        vThis.initialLoad=false
        vThis.room = res.data
        if (vThis.room.billing.couplesAllowed==false){
          vThis.user.couple=="single"
          vThis.userIs="single"
          vThis.couple=false
        }
        vThis.apartment = res.data
        axios.get('/users/' + vThis.apartment.general.landlordId + '.json')
          .then(res => {
            vThis.landlord = res.data
            vThis.landlord.id=vThis.apartment.general.landlordId
          })
          .catch(err=>{
            console.log('error: ',err);
          })

        vThis.setContractDates()
        this.getCurrency()
      })
      .catch(err=>{
        console.log('error: ',err);
      })

    }

  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
      },
      birthday: {
        required
      },
      phone: {
        required,
        phone
      },
      gender: {
        required
      },
      passport: {
        required
      },
      couple: {
        required
      },
      occupation: {
        required
      },
      school: {
        required
      },
      about: {
        required,
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    totalRent(){
      let tMod = moment(this.contractDates.mod).add(1, 'days')
      let months = tMod.diff(moment(this.contractDates.mid), 'months')
      let tMid = moment(this.contractDates.mid).add(months, 'months')
      let days = tMod.diff(moment(tMid), 'days')
      // console.log(months, 'month', days, 'days');
      let DiM = moment(this.contractDates.mod).daysInMonth()
      // console.log(months * parseInt(contract.price) + (days / DiM) * parseInt(contract.price), 'is the total amout');

      return months * parseInt(this.roomPrice+this.utilities.amount) + (days / DiM) * parseInt(this.utilities.amount+this.roomPrice)
    },
    utils(){
      if (this.utilities.amount!=0){
        return ' + '+this.utilities.amount+" (utilities)"
      } else if (this.utilities.name.toLowerCase().includes('included')){
        return ''
      } else {
        return ' + Variable utilities'
      }
    },
    startDate(){
      let midDate=moment(this.contractDates.mid).date()
      if (midDate<=10){
        return moment(this.contractDates.mid).date(20)
      } else  if (midDate<=25){
        return moment(this.contractDates.mid).date(10).add(1,"M")
      } else {
        return moment(this.contractDates.mid).date(20).add(1,"M")
      }

    },
    dueDate(){
      if ( moment(this.contractDates.mid).format('D')!= moment(this.contractDates.mod).add(1,"d").format('D')){
        this.specialRent=true
        return "20th of the month"
      } else {
        let midDate=moment(this.contractDates.mid).date()
        if (midDate>25|| midDate<=10){
          return "20th of the month"
        } else {
          return "5th of the month"
        }
      }

    },
    visit(){
      if (this.$route.query.visit){
        console.log('this is a visit request');
        return true
      } else {
        console.log('this is a booking');
        return false
      }
    },
    phoneError(){
      const errors=[]
      if (!this.$v.user.phone.$dirty) return errors
      !this.$v.user.phone.required && errors.push('Please add a phone number')
      !this.$v.user.phone.phone && errors.push('Please use this format (with country code - no spaces): "+32XXXXXXXXX"')
      return errors
    },
    nameError(){
      const errors=[]
      if (!this.$v.user.name.$dirty) return errors
      !this.$v.user.name.required && errors.push(this.$t('profile.nameHint'))
      return errors
    },
    aboutError(){
      const errors=[]
      if (!this.$v.user.about.$dirty) return errors
      !this.$v.user.about.required && errors.push(this.$t('profile.aboutHint'))
      return errors
    },
    communicationOptions(){
      let t=['Email', 'Phone', 'Line', 'WeChat', 'WhatsApp', 'Facebook']
      if (this!=undefined){
        t=[
          {
            text:this.$t('tenants.email'),
            value:'Email',
          },
          {
            text:this.$t('profile.lineApp'),
            value:'Line',
          },{
            text:this.$t('profile.phone'),
            value:'Phone',
          },{
            text:this.$t('profile.wechatApp'),
            value:'WeChat',
          },{
            text:this.$t('profile.whatsapp'),
            value:'WhatsApp',
          },{
            text:this.$t('profile.facebookApp'),
            value:'Facebook',
          },
        ]
      }
      return t
    },

    genders:function() {
        let t=[{
            text: 'Male',
            value: "m"
          },
          {
            text: 'Female',
            value: "f"
          }
        ]
        if(this!=undefined){
          t=[{
              text: this.$t("profile.male"),
              value: "m"
            },
            {
              text: this.$t("profile.female"),
              value: "f"
            }
          ]
        }
        return t
    },
    occupationOptions:function(){
      let t=[{text: 'Student',value: "student"}, {text: 'Worker',value: 'worker'}]
      if (this!=undefined){
        t=[{text: this.$t('otherTenants.student'),value: "student"}, {text: this.$t('otherTenants.worker'),value: 'worker'}]
      }
      return t
    },
    maxBday:function(){
      return moment().subtract(18,"y").format("YYYY-MM-DD")
    },
    diffMid: function(){
      if(Math.abs(moment(this.dates.mid).diff(moment(this.contractDates.mid),'d'))>=1 ){
        return true
      } else {
        return false
      }
    },
    diffMod: function(){
      if(Math.abs(moment(this.dates.mod).diff(moment(this.contractDates.mod),'d'))>=1 ){
        return true
      } else {
        return false
      }
    },
    coupleVals: function() {
      let ar = [{
        text: 'Single',
        value: 'single',
        disabled: false
      }, {
        text: 'Couple',
        value: 'couple',
        disabled: false
      }]
      if(this!=undefined){
        ar=[{
          text:this.$t("requestsInbox.onePerson"),
          value:"single",
          disabled:false,
        },{
          text:this.$t("userRequest.couple"),
          value:"couple",
          disabled:false
        }]
      }
      // console.log('couples are allowed: ', this.room.billing.couplesAllowed);
      if (!this.room.billing.couplesAllowed) {
        // console.log('couples not allowed')
        ar[1].disabled=true
      } else {
        // console.log('couplesAllowed');
      }
      return ar
    },
    roomPrice: function() {
      // console.log("GET ROOMPRICE")
      let t = Number(this.rent)
      if (this.user.couple == "couple") {
        t = t + Number(this.room.billing.couplesExtraUtilities)
      }
      return t

    },
    eurPrice: function() {
      return Math.ceil(this.fx * this.roomPrice)
    },
    absDiscount: {
      get(){
        if (this.discount>0){
          return Math.ceil(this.serviceFee * this.roomPrice * this.discount)
        } else {
          return 0
        }
      },
      set(val){
        this.discount=val/(this.serviceFee * this.roomPrice)
      }
    },
    commission: function() {
      let comm = Math.ceil((((this.roomPrice+this.utilities.amount) * this.serviceFee) + this.utilities.amount + this.roomPrice) * this.transactionCost + ((this.roomPrice+this.utilities.amount) * this.serviceFee))
      return comm
    },
    transactionCost(){
      let t=.028
      return t
    },
    utilities(){
      let t={}
      t.name=""
      if (this.$route.params.type == "room") {
          t.name=this.apartment.contractDetails.utilities
          if (this.apartment.contractDetails.utilitiesAmount && this.apartment.contractDetails.utilities=='Fixed monthly amount'){
            t.amount= parseInt(this.apartment.contractDetails.utilitiesAmount)
          } else {
            t.amount=0
            t.comment=this.apartment.contractDetails.utilitiesComment
          }
        } else {
        t.name=this.room.billing.utilities
        if (this.room.billing.utilitiesAmount && this.room.billing.utilities=='Fixed monthly amount'){
          t.amount= parseInt(this.room.billing.utilitiesAmount)
        } else {
          t.amount=0
          t.comment=this.apartment.contractDetails.utilitiesComment

        }
      }
      return t
    },

    total: function(){
      return  this.roomPrice+this.commission-this.absDiscount+this.utilities.amount

    },
    totalEur: function() {
      // console.log(this.fx)
      // console.log('THE TOTAL:' , this.total)
      // console.log('the total euros:',Math.ceil(this.fx * this.total))

      return Math.ceil(this.fx * this.total)
    },
  },
  filters:{
    dates(t){
      if (t!=undefined){
        return moment(t).format('LL')
      }
    }
  },
  components: {
    AddCard,
    SummaryCard,
    Loading,
    NationalityDropdown,
  }
}
</script>
<style lang="scss" scoped>
.v-carousel__next .v-btn,
.v-carousel__prev .v-btn {
    color: #00bfff;
}

.carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    object-fit: contain;

}
.rules-title {
    margin-bottom: 0;
}
.house-rule {
    height: 300px;
    color: (0,0,0,.6);
    text-shadow: none;
    padding: 0 2em;
}
.rules-carousel {
    margin-top: 5px;
    max-height: 400px;
}
.not-applicable {
    color: rgba(0,0,0,.38);
}
.houserules-list {
    line-height: 1.4em;
    padding-left: 0;
    margin-top: 0;
}
.about::placeholder {
    font-size: 0.75em;
    color: rgba(0,0,0,.38);
}
.md-float-right {
    float: right;
}
.low-em {
    font-size: 0.875em;
    color: rgba(0,0,0,.38);
}
.indicators-group {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.indicator {
    cursor: pointer;
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2px rgba(0,0,0,.38);
    margin-right: 3px;
    margin-left: 3px;
}
.indicator.focus {
    border: solid 2px #00bfff;
}
.done {
    border: solid 2px #00bfff;
    background-color: #00bfff;
}
.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}
.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}
.validate {
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.em {
    color: #00bfff;
}
.payments {
    width: 100%;
}
.rules-helper {
    margin-top: 1rem;
}
.houserules-item {}
.md-darker {
    color: rgba(0,0,0,.87);
}
.online-rent {
    padding-bottom: 1rem;
}
.validate {
    padding: 15px;
    color: #00bfff;
}
.parent-group {
    display: flex;

}
.summery-part {
    order: 2;
}

.big-part {
    order: 1;
}
.md-field {
    white-space: nowrap;
}
.error-text {
    color: red;
}

@media only screen and (max-width: 960px) {
    .validate {
        bottom: 30px;
    }
    .parent-group {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .validate {
        margin-bottom: 0;
    }
    .hide-on-small {
        display: none;
    }
    .houserules-item {
        font-size: 0.75em;
    }
    .couple {
        white-space: nowrap;
    }
}
@media only screen and (min-width: 600px) {
    .hide-on-large {
        display: none;
    }
}
#tappay-iframe {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: .578571em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color .1s ease, border-color .1s ease;
  transition: color .1s ease, border-color .1s ease;
  width: 100%;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00bfff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
.boxed{
  border-radius:5px;
  border: solid thin rgba(0,0,0,.38)
}
.rent-title{
  font-size: 1.2rem;
  color:rgba(0,0,0,0.68)
}
.rentInput{
  border-radius:5px;
  width:80%;
  font-size: 1.1rem;
  border: solid thin rgba(0,0,0,.38)
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


</style>
