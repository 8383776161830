<template>
<div id="">
  <v-card>
    <v-img
      :src="thumbnailUrl"
      alt="room thumbnail"
    ></v-img>

    <v-card-title primary-title>
      <v-layout  wrap>
        <v-flex xs12>
          <h3 class="headline">{{$t('bookingRequest.bookingSummary')}}</h3>
          <h4 class="subheading">{{room.general.title}}</h4>
        </v-flex>
        <v-flex xs6>
          <span>{{contractLength}}</span>
        </v-flex>
        <v-flex xs6>
          {{couples}}
        </v-flex>
        <v-flex xs12>
          <span>
          {{contractFlexibility}}
          </span>
        </v-flex>
      </v-layout>

    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>

      <v-layout  wrap align-center>
        <v-flex xs12 class="title">
          {{$t('userRequest.contractDates')}}
        </v-flex>
        <v-flex xs5 class="lighten-1">
          {{$t('userRequest.mid')}}:
          <v-tooltip bottom hover v-if="diffMid">
            <span slot="activator" class="MRAIcon_info my-tooltip"></span>
            <span>{{$t('bookingRequest.midDiffText')}}
            <br>{{$t('bookingRequest.dateDiffText1')}}
            <br>{{$t('bookingRequest.dateDiffText2')}}</span>
          </v-tooltip>
          <br>
          <span class="subheading" :class="diffMid ? 'date-changed':''">{{mid}}</span>
        </v-flex>
        <v-flex xs2>
          <v-icon>arrow_forward</v-icon>
        </v-flex>
        <v-flex xs5 class="text-xs-right">
          {{$t('userRequest.mod')}}:
          <v-tooltip bottom hover v-if="diffMod">
            <span slot="activator" class="MRAIcon_info my-tooltip"></span>
            <span>{{$t('bookingRequest.modDiffText')}}
            <br>{{$t('bookingRequest.dateDiffText1')}}
            <br>{{$t('bookingRequest.dateDiffText2')}}</span>
          </v-tooltip>

          <br>
          <span class="subheading" :class="diffMod ? 'date-changed':''">{{mod}}</span>

        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout  wrap>
        <v-flex xs12 class="title">
          {{$t('userRequest.payment')}}:

        </v-flex>
        <v-flex xs12 class="subheading">
          {{$t('userRequest.toMRA')}}:
        </v-flex>
        <v-flex xs9>
          {{$t('userRequest.firstRent')}}:
        </v-flex>
        <v-flex xs3 class="text-xs-right">
            {{rent}} NT$
        </v-flex>
        <v-flex xs9 v-if="utilities>0">
          {{$t('billing.utilities')}}:
        </v-flex>
        <v-flex xs3 class="text-xs-right" v-if="utilities>0">
            {{utilities}} NT$
        </v-flex>
        <v-flex xs9 v-if="couple">
          {{$t('userRequest.couplesSurcharge')}}:
        </v-flex>
        <v-flex xs3 class="text-xs-right" v-if="couple">
          {{room.billing.couplesExtraUtilities}} NT$
        </v-flex>
        <v-flex xs9>
          <v-tooltip bottom>
           <span slot="activator">{{$t('userRequest.fee')}} <span class="MRAIcon_info my-tooltip"></span></span>
           <span>{{$t('userRequest.feeHelp1')}}
               <br>{{$t('userRequest.feeHelp2')}}
               <br>{{$t('userRequest.feeHelp3')}}
               <br>{{$t('userRequest.feeHelp4')}}
           </span>
         </v-tooltip>
        </v-flex>
        <v-flex xs3 class="text-xs-right">
          {{serviceFee}} NT$
        </v-flex>
        <v-flex xs9 v-if="discount!=0">
          {{$t('userRequest.discount')}}
        </v-flex>
        <v-flex xs3 class="text-xs-right" v-if="discount!=0">
          - {{discount}} NT$
        </v-flex>
        <v-flex xs9>

        </v-flex>
        <v-flex xs3>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs9 class="body-2">
          {{$t('userRequest.total')}}:
        </v-flex>
        <v-flex xs3 class="text-xs-right body-2" id="price">
          {{total}} NT$
        </v-flex>
        <!-- <v-flex xs9 class="body-2" v-if="displayCurrency!='TWD'">
          {{$t('userRequest.total')}} {{displayCurrency}}:
        </v-flex>
        <v-flex xs3 class="text-xs-right body-2 " v-if="displayCurrency!='TWD'">
          <h4>{{totalFx}} {{displayCurrency}}</h4>
        </v-flex> -->
        <v-flex xs12 class="caption" v-if="!visit">
          {{$t('userRequest.charge')}}
        </v-flex>
      </v-layout>

    </v-card-text>
  </v-card>
</div>
</template>
<script>
import moment from 'moment'
import config from '../../config'

export default {
  name: "",
  props: ['room','apartment', 'utilities', 'diffMid', 'diffMod','dates','rent', 'serviceFee', 'total', 'totalFx', 'currency', 'couple', 'discount','visit'],
  data: () => ({
  }),
  computed: {
    thumbnailUrl:function(){
      if (this.room.visual.photos) {
        return config.PHOTOURL+this.room.visual.photos[0].id
      }
    },
    contractLength:function(){
      switch(this.apartment.contractDetails.contractLength){
        case "1 month":
          return this.$t('contractDetails.minLengthOptions.one')+' '+this.$t('details.contract')
        case "3 months":
          return this.$t('contractDetails.minLengthOptions.three')+' '+this.$t('details.contract')
        case "5 months":
          return this.$t('contractDetails.minLengthOptions.five')+' '+this.$t('details.contract')
        case "12 months":
          return this.$t('contractDetails.minLengthOptions.twelve')+' '+this.$t('details.contract')
        case "Open Contract":
          return this.$t('contractDetails.minLengthOptions.open')
        default:
         return ""
      }
    },

    contractFlexibility:function(){
      switch (this.apartment.contractDetails.contractFlexibility){
        case "Starts and ends any day":
          return this.$t('contractDetails.contractFlexibilityOptions.any')
        case "Starts any day for 1 month":
          return this.$t('contractDetails.contractFlexibilityOptions.oneMonth')
        case "Starts first or 15th":
          return this.$t('contractDetails.contractFlexibilityOptions.firstOr')
        case "Starts always on the first":
          return this.$t('contractDetails.contractFlexibilityOptions.first')
        default:
          return ""
      }
    },
    couples: function(){
      if (this.room.billing.couplesAllowed) {
        return this.$t("billing.couplesAllowed")
      } else {
        return this.$t("details.couplesNotAllowed")
      }

    },
    displayCurrency: function(){
      if(this.currency && this.currency!=""){
        return this.currency
      } else{
        return 'EUR'
      }
    },
    mid:function(){
      return moment(this.dates.mid).format('ll')
    },
    mod: function(){
      return moment(this.dates.mod).format('ll')
    }
  },
  created() {
    //do something after creating vue instance
    // console.log(this.dates.mid, 'Dates mid')
    // console.log(this.serviceFee, this.total, this.totalFx)
    // console.log(this.roomSummary.photos[0].url)
    }
}
</script>
<style lang="scss" scoped>
.my-card-media {
    max-height: 250px;
    overflow: hidden;
}
.my-card-media img {
    object-fit: cover;
    object-position: center;
}
.my-card-title {
    color: rgba(0,0,0,.87);
    font-size: 1.25rem;

}
.md-card-content{
  color: rgba(0,0,0,.6);
}
.md-card-header{
  color: rgba(0,0,0,.6);
}
.divider {
    margin: 1rem;
}
.subheader {
    margin-top: -1rem;
    margin-bottom: 0.5rem;
}
.my-tooltip {
    position: relative;
    display: inline-block;
}

/* my-tooltip text */
.my-tooltip .my-tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: rgba(0,0,0,.87);
    color: #fff;
    size: 0.75em;
    padding: 10px;
    border-radius: 6px;
    font-family: 'Roboto', 'Open Sans', 'Sans-serif';
    /* Position the tooltip text - see examples below! */
    position: absolute;
    line-height: 1.3em;
    top: -50px;
    left: -150px;
    z-index: 1;
}
.my-tooltip:hover .my-tooltiptext {
    visibility: visible;
}
.price-tag {
    font-size: 0.875rem;
}
.price {
    font-size: 1.5rem;
}
.sentence{
  margin:auto;
}
.md-right {
    text-align: right;
}
.paymentDetails div {
    text-align: right;
}
.price-div div {
    text-align: right;
}
.payment-details{
  padding-top: .5em;
  line-height: 2em;
}
.summary-title{
  margin-bottom:.5em
}
.date-changed{
  color:red;
}
@media only screen and (max-width: 600px) {

.my-tooltip.my-tooltiptext {
  max-width: 75vw;
  left: 0 !important;
    }
}

</style>
